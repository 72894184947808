import React, { useEffect } from 'react'
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from "react-share"
import YouTube from 'react-youtube'
import BlogRecommended from '../../../components/molecules/blog-recomended/blog-recomended'
import ProductsRelated from '../../../components/molecules/product-related/product-related'
import FacebookIcon from '../../../shared/assets/images/facebook.svg'
import TwitterIcon from '../../../shared/assets/images/twitter.svg'
import WhatsappIcon from '../../../shared/assets/images/whatsapp.svg'
import BreadCrumb from '../../../shared/components/atoms/breadcrumb/breadcrumb'
import CategoryTag from '../../../shared/components/atoms/category-tag/category-tag'
import InteractionArticle from '../../../shared/components/atoms/interaction-article/interaction-article'
import RichText from '../../../shared/components/atoms/rich-text/rich-text'
import SectionTitleMobile from '../../../shared/components/atoms/section-title-mobile/section-title-mobile'
import SectionCommentarys from '../../../shared/components/molecules/section-commentary/section-commentary'
import ArticleService from '../../../shared/services/article'
import homeLink from '../../../shared/utils/siteUrl'
import './article-content.scss'

const ArticleContent = (props) => {

  const { blogInfo, article, setModalActive, isMobile } = props
  const linkToShared = typeof window !== 'undefined' ? window.location.href : "/"

  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  const getParseDate = (date) => {
    let tempDate = date.split("/")
    return tempDate[0] + " de " + months[parseInt(tempDate[1]) - 1] + " " + tempDate[2]
  }

  const opts = {
    width: '100%',
  };

  useEffect(() => {
    let payload = {
      cmsId: article.contentful_id,
    }

    ArticleService.postRead(payload).then(res => {
    }).catch(e => console.log(e))

  }, [])

  return (
    <div className="f-article-container">
      <div className="f-breadcrum-position"><BreadCrumb mobile={isMobile} /></div>
      <div className="f-article-header-mobile">
        <SectionTitleMobile title="BLOG" redirection={blogInfo.slug} className="f-article-header" />
      </div>

      <figure className="f-article-banner-mobile">
        <img src={article.bannerDelArticulo[isMobile ? 1 : 0].file.url} alt={article.bannerDelArticulo[isMobile ? 1 : 0].description} />
      </figure>

      <div className="f-article-content">
        <div className="f-article">
          <div className="f-article-header">
            <div className="f-article-category-like">
              <CategoryTag textCategory={article.categoriasDelArticulo[0].nombreCategoria} category={article.categoriasDelArticulo[0].nombreCategoria} color={article.categoriasDelArticulo[0].colorCategoria} />

              <div className="f-likes-count">
                <InteractionArticle showCount={true} articleId={article.contentful_id} />
              </div>

            </div>

            <div className="f-article-title-container">
              <a href={homeLink + blogInfo.slug} className="f-return-icon"><i className="far fa-arrow-left"></i></a>
              <h1 className="f-article-title">{article.nombreDelArticulo}</h1>
            </div>

            {article && article.tieneVideo ?
              <div className="f-article-video">
                <YouTube
                  videoId={article.urlDelVideo.split('/')[article.urlDelVideo.split('/').length - 1]}
                  opts={opts}
                />
              </div>
              :
              <figure className="f-article-banner-desktop">
                <img src={article.bannerDelArticulo[isMobile ? 1 : 0].file.url} alt={article.bannerDelArticulo[isMobile ? 1 : 0].description} />
              </figure>
            }

            <div className="f-article-author-date">
              <span className="f-article-author">{article.autorDelArticulo}</span>
              <p className="f-article-date">
                {getParseDate(article.fechaDePublicacionDelArticulo) + (article.tiempoDeLectura ? " | ".concat(article.tiempoDeLectura) : '')}
              </p>
            </div>
          </div>

          {article.contenidoDelArticulo &&
            <RichText
              className="f-article-body"
              jsonInfo={article.contenidoDelArticulo.json}
            />
          }

          <div className="f-article-share-opinion">
            <div className="f-article-share-container">
              <p className="f-article-share-title">Compartir en:</p>

              <FacebookShareButton url={linkToShared}>
                <div className="f-article-icon-share">
                  <img src={FacebookIcon} alt="Facebook icon" />
                </div>
              </FacebookShareButton>

              <TwitterShareButton url={linkToShared} title={"Petys: " + article.nombreDelArticulo}>
                <div className="f-article-icon-share">
                  <img src={TwitterIcon} alt="Twitter icon" />
                </div>
              </TwitterShareButton>

              <WhatsappShareButton url={linkToShared} title={"Petys: " + article.nombreDelArticulo}>
                <div className="f-article-icon-share">
                  <img src={WhatsappIcon} alt="Whatsapp icon" />
                </div>
              </WhatsappShareButton>
            </div>
          </div>
        </div>

        {article.productosRecomendados &&
          <ProductsRelated
            parentSlug="productos/"
            referenciasProductosDestacados={article.productosRecomendados}
          />
        }

        <SectionCommentarys
          articleInfo={article}
          setModalActive={setModalActive}
          isMobile={isMobile}
          className="f-articles-commentaries"
        />

        {article.petysTeRecomienda &&
          <BlogRecommended
            articlesRecommended={article.petysTeRecomienda}
            blogInfo={blogInfo}
            isMobile={isMobile}
          />
        }
      </div>
    </div >
  )
}

export default ArticleContent;
import React from 'react';
import ArticleContent from '../../components/organisms/article-content/article-content';
import SEO from '../../shared/components/atoms/seo/seo';
import Layout from '../../shared/components/organisms/layout/layout';
import useMobie from '../../shared/hooks/useMobile';
import siteUrl from '../../shared/utils/siteUrl';
import { unicodeUtil } from '../../shared/utils/unicode-util';

const BlogexpertTemplate = props => {

  const { pageContext } = props;
  const { blogInfo, article } = pageContext;

  // send active modal to layout
  const [modalActive, setModalActive] = React.useState(null);
  const isMobile = useMobie();

  let schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": siteUrl + props.pageContext.slug
    },
    "headline": unicodeUtil(article.metaTitulo),
    "description": unicodeUtil(article.metaDescripcion ? article?.metaDescripcion?.metaDescripcion : ''),
    "image": article.bannerDelArticulo[isMobile ? 1 : 0].file.url,
    "author": {
      "@type": "Organization",
      "name": "Petys"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Grupo Familia S.A",
      "logo": {
        "@type": "ImageObject",
        "url": process.env.DEFAULT_LOGO,
        "width": "",
        "height": ""
      }
    },
    "datePublished": article.fechaDePublicacionDelArticulo,
    "dateModified": article.fechaDePublicacionDelArticulot
  }

  return (
    <Layout activeMenu={2} modalActive={modalActive} logoMobile={false} logoSearch={false}>
      <SEO
        title={article.metaTitulo ? article.metaTitulo : ''}
        description={article.metaDescripcion ? article?.metaDescripcion?.metaDescripcion : ''}
        slug={siteUrl + (article.slugDelArticulo ? blogInfo.slug + article.slugDelArticulo : '')}
        image={article.bannerDelArticulo[isMobile ? 1 : 0].file.url}
        script={schema}
      />
      <ArticleContent
        setModalActive={setModalActive}
        isMobile={isMobile}
        blogInfo={blogInfo}
        article={article}
      />
    </Layout>
  )
}

export default BlogexpertTemplate;